import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import Widget from "../components/widgets"
import Components from "../components"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { isInBrowser } from "../utils/helpers"

export interface IContentTemplate {
  title: string
  seoTitle: string
  seoDescription: string
  components: Array<any>
}

export interface IContentTemplateProps {
  contentfulCareersContentPage: IContentTemplate
}

interface IContentTemplatePageContext {
  id: string
  slug: string
  locale: string
}

const ContentTemplate: React.FC<PageProps<IContentTemplateProps, IContentTemplatePageContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersContentPage.seoTitle || data.contentfulCareersContentPage.title,
    description: data.contentfulCareersContentPage.seoDescription,
    image: "",
  }


  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout seoData={seoData} dispatch={() => false}>
        {data.contentfulCareersContentPage.components.map((c: any, id: number) => {
          if (!isInBrowser) {
            return
          }

          if (c.title && c.title.includes("Widget")) {
            return <Widget {...c} key={id} />
          } else if (c.__typename) {
            if (c.__typename === "ContentfulCareersComponentHero") {
              c.className = "jobs-hero culture-hero"
            }
            if (c.title === "Careers > Benefits > Hero") {
              c.className = "teams-listing talent-rewards"
            }
            return React.createElement((Components as any)[c.__typename], {
              key: id,
              ...c,
            })
          }
        })}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default ContentTemplate

export const query = graphql`
  query ($id: String!) {
    contentfulCareersContentPage(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      components {
        ...ComponentRichText
        ...ComponentCard
        ...ComponentCTA
        ...ComponentHero
        ...ComponentBenefits
      }
    }
  }
`
