import ComponentRichText from "./RichText"
import ComponentHero from "./Hero"
import ComponentPodcast from "./Podcast"
import ComponentBenefits from "./Benefits"
import ComponentCard from './Cards'

export default {
  ContentfulComponentRichText: ComponentRichText,
  ContentfulCareersComponentHero: ComponentHero,
  ContentfulComponentPodcast: ComponentPodcast,
  ContentfulCareersComponentBenefits: ComponentBenefits,
  ContentfulComponentCard: ComponentCard,
}
