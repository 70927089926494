import React from "react"
import { ComponentBenefitsProps } from "./typings"
import { graphql } from "gatsby"
import Main from "./main"

const ComponentBenefits: React.FC<ComponentBenefitsProps> = props => {
  switch (props.variation) {
    case "Default":
      return <Main {...props} />

    default:
      return (
        <section>
          <div>
            <strong>COMPONENT:</strong> ComponentBenefits
          </div>
          <div>
            <strong>TITLE:</strong> {props.title}
          </div>
          <div>
            <strong>VARIATION:</strong> {props.variation}
          </div>
        </section>
      )
  }
}

export default ComponentBenefits

export const query = graphql`
  fragment ComponentBenefits on ContentfulCareersComponentBenefits {
    title
    variation
    __typename
    benefits {
      title
      description {
        description
      }
      image {
        fluid: gatsbyImageData(placeholder: NONE width: 450 quality: 100 formats: [AUTO, WEBP])       
        title
      }
      icon {
        file {
          url
        }
        title
      }
    }
  }
`
