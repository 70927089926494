import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ComponentBenefitsProps } from "../typings"
import "./style.scss"

const Main: React.FC<ComponentBenefitsProps> = ({ benefits }) => {
  return (
    <section className="benefits-section">
      <div className="container">
        <div className="row clear grid benefit-listing">
          {benefits.map(benefit => (
            <div className="col-md-6 col-lg-4">
              <div className="card card-benefit">
                <div className="card-image">
                  <GatsbyImage className="img-fluid" image={benefit.image.fluid} alt={benefit.image.title || ""} />
                  {benefit.icon?.file?.url && (
                    <img className="img-icon" src={benefit.icon?.file?.url} width="84" height="95" alt="" />
                  )}
                </div>
                <div className="card-body">
                  <h2 className="card-title">{benefit.title}</h2>
                  <p className="card-text">{benefit.description.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Main
